import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useMedia } from 'react-use'
import { isClient, breakpoints, useBreakpoint } from '../styles/utils'

import { Layout, Block } from '../components'
import ArrowRight from '../assets/images/arrow-right.inline.svg'

import { container, padding, bgImage, colours, type, wrapper, pagePaddingTop, linedText, textOffset, rolloverAnimation } from '../styles/global'
import { media } from '../styles/utils'

const Project = (props) => {
    const data = props.pageContext
    const { title, hero_image, intro_text } = data;
    const screenIsTablet = useBreakpoint('tablet')

    const renderHero = () => {
        return (
            <Hero>
                <Container>
                    <Heading
                        as={'h1'}
                    >
                        {data.title}. <span>{data.location}</span>
                    </Heading>
                </Container>

                {hero_image && (
                    <Image
                        key={hero_image?.sizes?.full_width}
                        src={hero_image?.sizes?.full_width}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
            </Hero>
        )    
    }

    const renderIntro = () => {
        return (
            <Container>
                <Intro>
                    <IntroText>
                        <Subheading as="h2">{data.sub_heading}</Subheading>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: data.intro_text,
                            }}
                        />
                        {!screenIsTablet && renderShare('large')}
                    </IntroText>

                    <Aside>
                        {data?.metadata?.map?.(({ heading, items }) => (
                            <React.Fragment key={heading}>
                                <Subheading>{heading}</Subheading>
                                {items && (
                                    <ul>
                                        {items.map(({ text }) => (
                                            <li key={text}>{text}</li>
                                        ))}
                                    </ul>
                                )}
                            </React.Fragment>
                        ))}
                    </Aside>
                </Intro>
            </Container>
        )
    }

    const renderShare = (breakpointStyle) => {
        if (!isClient()) return;

        const socials = [
            {
                label: 'Twitter',
                link: `https://twitter.com/intent/tweet?url=${encodeURI(window.location)}`
            },
            {
                label: 'Pinterest',
                link: `http://pinterest.com/pin/create/link/?url=${window.location}`
            },
            {
                label: 'LinkedIn',
                link: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location}`
            },
            {
                label: 'Facebook',
                link: `https://www.facebook.com/sharer.php?u=${window.location}`
            },
        ]

        const items = socials.map((option) => {
            return (
                <a
                    key={option.link}
                    href={option.link}
                    target={'_blank'}
                >
                    {option.label}
                </a>
            )
        })
        return (
            <ShareList breakpointStyle={breakpointStyle}>
                <h2>Share</h2>

                <ArrowRight />

                <ul>{items}</ul>
            </ShareList>
        )
    }

    const renderBlocks = () => {
        if (!data.blocks) return;
        
        console.log(data.blocks)

		const items = data.blocks.map((block, i) => {  
            const noContainer = block.width == 'fullwidth'; 

            return (
                <Block
                    layout={block.acf_fc_layout}
                    wrapContainer={!noContainer}
                    key={i}
                    {...block}
                />
            )
        })
        
        return <Blocks>{items}</Blocks>
    }

    const renderNextProject = () => {
        if (!data.nextProject) return;

        return (
            <NextProject
                to={`/case-studies/${data.nextProject.slug}`}
            >
                <Container>
                    <h3>Next Project</h3>
                    <Subheading>{data.nextProject.title}</Subheading>
                    <p
                        dangerouslySetInnerHTML={{__html:   data.nextProject.listing_description}}
                    />
                </Container>

                <ImageWrapper>
                    {data.nextProject.hero_image && (
                        <Image
                            key={data.nextProject.hero_image?.sizes?.medium2}
                            src={data.nextProject.hero_image?.sizes?.medium2}
                        >
                            {(src, loading) => {
                                return (
                                    <BGImage
                                        image={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </ImageWrapper>
            </NextProject>
        )
    }

	return (
        <Layout 
            meta={data && data.seo} 
            logoTheme="dark"
        >
            <Wrapper>
                {renderHero()}
                {renderIntro()}
                {screenIsTablet && renderShare('small')}
                
                {renderBlocks()}
                {renderNextProject()}
            </Wrapper>
        </Layout>
    )
}


// Shared

const Heading = styled.h2``
const Subheading = styled.h3`
    color: #000;
    margin-bottom: 6px;
    font-size: 20px;

    ${media.desktopSmall`
        font-size: 16px;
    `}

    ${media.phone`
        font-size: 18px;
        line-height: 1.38em;
        margin-bottom: 4px;
    `}
`

const Description = styled.div``
const IntroText = styled.div``

const Aside = styled.aside`
    max-width: 500px;
    flex: 1;
    font-size: 20px;
    color: ${colours.darkRed};

    ${media.desktopSmall`
        font-size: 16px;
    `}

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 1.5;
        margin-bottom: 40px;
    }

    li {
        border-bottom: 1px solid ${colours.darkRed};
    }

    ${media.tablet`
        font-size: 18px;
        line-height: 2;
        max-width: inherit;
    `}
`

const ShareList = styled.section`
    display: flex;
    align-items: center;
    color: ${colours.darkGray};
    margin-top: 75px;
    margin-bottom: 130px;
    font-size: 16px;

    ${media.desktopSmall`
        font-size: 14px;
    `} 

    ${(props) => {
        if (props.breakpointStyle === 'small')
            return css`
                ${container}
	            ${padding}
            `
    }}

    h2 {
        font-size: inherit;
        transform: translateY(-2px);
    }

    svg {
        width: 25px;
        margin-left: 7px;
        margin-right: 50px;
        transform: translateY(-2px);
    }

    a {
        color: inherit;
        font-size: inherit;
        margin-right: 50px;
        color: #888888;
        ${rolloverAnimation};
        padding-bottom: 6px;

        &::before {
            background-color: ${colours.darkRed};
        }

        &:hover,
        &:focus {
            &, > * {
                color: ${colours.darkRed};
            }

            &::after {
                transform: none;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        display: flex;
    }

    ${media.tablet`
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 0;
        

        ul {
            display: flex;
            width: 100%;
            margin-top: 17px;
        }

        a {
            margin-right: auto;
        }

        svg {
            width: 13px;
            position: relative;
            top: 1px;
            margin-left: 5px;
        }
    `}
`


const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
    min-height: 500px;
    padding-bottom: 57.89%;
    z-index: 1;
    position: relative;
`

// Layout


const Wrapper = styled.div`
    ${wrapper}
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}

    > ${Heading} {
        ${type.heading2};

        span {
            color: ${colours.lightGray};
            
            ${media.phone`
                display: block;
                font-size: 18px;
                line-height: 1;
                color: ${colours.midGray};
            `}
        }
}
`

// Hero

const Hero = styled.div`
    width: 100%;

    ${Container} {
        ${pagePaddingTop};
        position: sticky;
        top: 0;
        margin: 0 auto;
        z-index: 0;

        ${Heading} {
            margin-bottom: 60px;

            ${media.phone`
                margin-bottom: 35px;
                line-height: 1.15em;

                span {
                    margin-top: 8px;
                }
            `}
        }
    }

    ${BGImage} {
        z-index: 2;
        position: relative;
        transform: translate3d(0,0,0);

    }
`

// Intro

const Intro = styled.div`
    display: flex;
    padding-top: 200px;

    ${IntroText} {
        flex: 1;

        ${Description} {
            p {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        ${media.phone`
            margin-bottom: 75px;
        `}
    }

    ${Aside} {
        ${Subheading} {
            ${media.desktopSmall`
                font-size: 16px;
            `}
        }
    }

    ${Description} {
        margin-right: 50px;
        max-width: 800px;
        color: ${colours.midGray};
        ${textOffset};


        ${media.tablet`
            margin-right: 10px;
        `}
    }

    ${media.tablet`
        display: block;
    `}

    ${media.phone`
        padding-top: 40px;
    `}
`

// Blocks

const Blocks = styled.div`
    width: 100%;
    padding-top: 200px;
    padding-bottom: 80px;

    ${media.phone`
        padding-top: 170px;
    `}
`


const ImageWrapper = styled.div`
    overflow: hidden;
    margin-top: 50px;
`

// Next Project

const NextProject = styled(Link)`
    background-color: #f5f5f5;
    padding-top: 50px;
    width: 100%;

    &, 
    h3, 
    p {
        transition: all 0.35s ease;
    }

    h3:first-child {
        ${media.desktopSmall`
            font-size: 14px;
        `}
    }

    ${Subheading} {
        color: #000;
        margin-bottom: 6px;
        font-size: 20px;

        ${media.desktopSmall`
            font-size: 16px;
        `}
    }

    &:hover,
    &:focus {
        background-color: ${colours.darkRed};

        h3 {
            color: ${colours.red};
        }

        ${Subheading} {
            color: white;
        }

        p {
            color: white;
            opacity: 0.7;
        }

        ${BGImage} {
            transform: scale(1.02);
        }
    }

    h3 {
        color: ${colours.darkRed};
        font-size: 16px;
        margin-bottom: 5px;
    }

    p {
        color: ${colours.midGray};
        margin-top: 5px;
        margin-bottom: 0;
        max-width: 600px;
        ${textOffset};
    }

    ${ImageWrapper} {
        max-height: 540px;
        margin-top: 137px;

        ${media.phone`
            margin-top: 46px;
        `}
    }

    ${BGImage} {
        transition: transform 800ms ease-in-out;
        transform: scale(1.001);
        min-height: 250px;
        padding-bottom: 40%;
    }
`


export default Project
